
import { AppActionTypes, useStore } from "@/store";
import { defineComponent, computed } from "vue";
import { routePaths } from "@/router/routePaths";

export default defineComponent({
    setup() {
        const { dispatch, state } = useStore();
        dispatch(AppActionTypes.getStickers);

        const earnedStickers = computed(() =>
            state.stickers.filter((x) => x.isEarned)
        );
        return {
            stickers: computed(() =>
                earnedStickers.value.length <= 4
                    ? earnedStickers.value.slice(0, 3)
                    : earnedStickers.value
            ),
            numberOfStickers: computed(() => earnedStickers.value.length),
            routePaths
        };
    }
});
