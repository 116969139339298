
import { useStore } from "@/store";
import { defineComponent, computed } from "vue";
import NavigationTileVue from "./NavigationTile.vue";
import { menuItems, library } from "@/components/layout/menu";
import { event } from "vue-gtag";
import { MenuItem } from "@/models/MenuItem";

export default defineComponent({
    components: {
        "navigation-tile": NavigationTileVue
    },
    setup(props) {
        const store = useStore();

        const onItemClick = (menuItem: MenuItem): void => {
            switch (menuItem.name) {
                case library.name:
                    if (!store.state.user?.hasLibrary) {
                        // Update Google Analytics event in click handler
                        event("lms_espired_library_purchase");
                    } else {
                        event("lms_espired_library_access");
                    }
                    break;
            }
        };

        return {
            onItemClick,
            tiles: computed(() => {
                if (!library.tileItem) {
                    return;
                }

                // changes the button text based on library access
                if (store.state.user?.hasLibrary) {
                    library.tileItem.linkText = "Browse The Library";
                } else {
                    library.tileItem.linkText = "Purchase Access To Library";
                }

                // sets url for library from the store
                library.href = store.state.user?.libraryUrl ?? "";

                return menuItems
                    .filter((x) => x.tileItem)
                    .sort(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        (a, b) => a.tileItem!.sortOrder - b.tileItem!.sortOrder
                    );
            })
        };
    }
});
