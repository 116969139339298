
import { computed, defineComponent } from "vue";
import { routeNames } from "@/router/routeNames";
import CourseCardVue from "@/components/CourseCard.vue";
import NewStickersVue from "@/components/NewStickers.vue";
import StudentRankVue from "@/components/StudentRank.vue";
import { AppActionTypes, useStore } from "@/store";

export default defineComponent({
    components: {
        "course-card": CourseCardVue,
        "new-stickers": NewStickersVue,
        "student-rank": StudentRankVue
    },
    setup() {
        const { state, dispatch } = useStore();
        dispatch(AppActionTypes.getCourseItems);
        dispatch(AppActionTypes.getRecentCourses);
        dispatch(AppActionTypes.getRank);

        const recentCourses = computed(() => {
            if (!state.recentCourses || !state.courseItems) {
                return [];
            }

            const courses = state.recentCourses;
            const minimumCount = 3; // There should be a minimum of 3 courses displayed on the page.

            if (courses.length < minimumCount) {
                // Add unowned courses if there are less than 3 recent courses
                const unownedCourses = [
                    ...state.courseItems.filter((c) => !c.isOwned)
                ];

                courses.push(
                    ...unownedCourses.splice(0, minimumCount - courses.length)
                );
            }

            return courses;
        });

        return {
            routeNames,
            recentCourses,
            rank: computed(() => state.rank || {})
        };
    }
});
