
import { COLOR_DEFAULT } from "@/helpers";
import { MenuItem } from "@/models/MenuItem";
import { defineComponent, PropType } from "vue";

const __default__ = defineComponent({
    props: {
        menuItem: {
            required: true,
            type: Object as PropType<MenuItem>
        }
    },
    setup(props) {
        return {
            borderColorHex:
                props.menuItem.tileItem?.borderColorHex ?? COLOR_DEFAULT
        };
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "11945141": (_ctx.borderColorHex)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__