import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cd239be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column align-items-center banner-logo-container" }
const _hoisted_2 = { class: "card-title mb-1 font-weight-bold" }
const _hoisted_3 = { class: "card-text card-text-courses" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_content_card, { "border-color-hex": _ctx.borderColorHex }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "image-text-overlay text-middle-center course-card-image",
            style: _normalizeStyle({
                            backgroundImage: `url(${_ctx.menuItem.tileItem?.image})`
                        })
          }, null, 4)
        ])
      ]),
      footer: _withCtx(() => [
        (_ctx.menuItem.external)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.menuItem.href,
              target: "_blank",
              class: "btn btn-bottom w-100 font-weight-bold"
            }, _toDisplayString(_ctx.menuItem.tileItem?.linkText), 9, _hoisted_4))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: _ctx.menuItem.route,
              class: "btn btn-bottom w-100 font-weight-bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.menuItem.tileItem?.linkText), 1)
              ]),
              _: 1
            }, 8, ["to"]))
      ]),
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.menuItem.name), 1),
        _createElementVNode("p", {
          class: "font-weight-bold font-raleway mb-0",
          style: _normalizeStyle({ color: _ctx.borderColorHex ?? 'black' })
        }, null, 4),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.menuItem.tileItem?.description), 1)
      ]),
      _: 1
    }, 8, ["border-color-hex"])
  ]))
}