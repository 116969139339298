
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import PageGreetingVue from "@/components/PageGreeting.vue";
import BannerMessageVue from "@/components/BannerMessage.vue";
import NoStudentsBannerMessageVue from "@/components/NoStudentsBannerMessage.vue";
import ParentHomeVue from "@/components/home/ParentHome.vue";
import StudentHomeVue from "@/components/home/StudentHome.vue";

export default defineComponent({
    components: {
        "page-greeting": PageGreetingVue,
        "parent-home": ParentHomeVue,
        "student-home": StudentHomeVue,
        "banner-message": BannerMessageVue,
        "no-students-banner-message": NoStudentsBannerMessageVue
    },
    setup() {
        const { state, getters } = useStore();

        const isParent = computed(() => getters.isParent);
        const isStudent = computed(() => getters.isStudent);

        const greetingMessage = computed(() => {
            if (state.user && getters.isAuthorized) {
                if (isParent.value) {
                    return "View the available products and review the progress of your students";
                } else {
                    return "It's time to get eSpired!";
                }
            }
            return "";
        });

        const banner = computed(() => state.settings?.bannerMessage);
        const hasStudents = computed(() => state.settings?.hasStudents);

        return {
            isParent,
            isStudent,
            showBanner: computed(() => banner.value && getters.isAuthorized),
            hasStudents,
            greetingTitle: computed(() =>
                state.user?.firstName
                    ? `Welcome, ${state.user?.firstName}! 👋`
                    : "Welcome! 👋"
            ),
            greetingMessage,
            banner,
            // we cannot use the loading getter because it will cause a loop with a child component making an api request
            isUserLoaded: computed(() => !!state?.user),
            loading: computed(() => getters.loading),
            isAuthorized: computed(() => getters.isAuthorized),
            helpEmail: computed(() => state.settings?.helpEmailAddress)
        };
    }
});
